import React, { useState } from 'react';
import { Gallery as ImageGallery } from 'react-grid-gallery'; // Renaming to avoid conflict with component name
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

const Certificates = () => {
  const [index, setIndex] = useState(-1);

  // Define the images directly within the component
  const images = [
    {
      src: '../images/certifications/1.png', // Thumbnail for grid
      original: '../images/certifications/1.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/2.png', // Thumbnail for grid
      original: '../images/certifications/2.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/3.png', // Thumbnail for grid
      original: '../images/certifications/3.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/4.png', // Thumbnail for grid
      original: '../images/certifications/4.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/5.png', // Thumbnail for grid
      original: '../images/certifications/5.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/6.png', // Thumbnail for grid
      original: '../images/certifications/6.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/7.png', // Thumbnail for grid
      original: '../images/certifications/7.png',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/8.jpg', // Thumbnail for grid
      original: '../images/certifications/8.jpg',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    {
      src: '../images/certifications/9.jpg', // Thumbnail for grid
      original: '../images/certifications/9.jpg',       // Full-size image for lightbox
      width: 820,
      height: 550,       
    },
    
    
    // Add more images as needed
  ];

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <section className="py-16 text-center bg-gray-700">
      <h2 className="text-6xl text-black font-semibold mt-12">Certifications, Licenses, & Accreditations</h2>

      {/* React Grid Gallery */}
      <div className="mt-8">
        <ImageGallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
      </div>

      {/* React Image Lightbox */}
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </section>
  );
};

export default Certificates;
