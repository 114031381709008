import React from 'react';

const ShipDesign = () => {
  return (
    <>
      <div className='ship'>

        {/* Overview Section */}
        <section className="py-16 text-center">
          <h2 className="text-4xl md:text-5xl lg:text-6xl highlight font-bold mt-12">Ship/Boat Design & Repair Division</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-3/4 lg:w-1/2 mt-4 mx-auto">
            <p className="text-gray-700">
              From custom design to comprehensive repair solutions, the Ship/Boat Design & Repair Division at SOLAS MODU Marine Services Pvt. Ltd. brings expertise and precision to every project. We specialize in crafting tailored designs and executing repairs for a wide range of marine vessels, including patrol boats, pilot boats, yachts, and ferries.
            </p>
          </div>
        </section>

        {/* Our Services Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold highlight">Our Ship/Boat Design & Repair Services</h2>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10 max-w-5xl mx-auto">
            {/* Custom Ship and Boat Design */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Custom Ship and Boat Design</h3>
              <p className="mt-4 text-gray-700">
                Fully customized design solutions tailored to each client’s unique needs, from conceptual and detailed design to 3D modeling and regulatory compliance checks.
              </p>
            </div>
            {/* Fast Craft Construction */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Fast Craft Construction</h3>
              <p className="mt-4 text-gray-700">
                We specialize in constructing durable, high-performance vessels like patrol boats, yachts, and ferries using advanced materials and engineering practices.
              </p>
            </div>
            {/* Hull Repairs and Structural Restoration */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Hull Repairs and Structural Restoration</h3>
              <p className="mt-4 text-gray-700">
                We restore hull integrity to meet Class and Flag State standards, addressing corrosion, impact damage, and structural fatigue.
              </p>
            </div>
            {/* Painting, Coating, and Corrosion Protection */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Painting, Coating, and Corrosion Protection</h3>
              <p className="mt-4 text-gray-700">
                High-quality coatings and anti-corrosion protection to preserve your vessel's structural integrity and appearance in harsh marine environments.
              </p>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold highlight">Why Choose SOLAS MODU for Ship/Boat Design & Repair?</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-3/4 lg:w-1/2 mt-4 mx-auto">
            <p className="text-gray-700">
              With years of experience and a team of skilled marine experts, we prioritize quality, compliance, and innovation. Our solutions are reliable, customized, and adhere to the highest industry standards.
            </p>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-16 px-4 sm:px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold highlight">Contact Us</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-3/4 lg:w-1/2 mt-4 mx-auto">
            <p className="max-w-3xl mx-auto text-lg text-gray-700">
              Whether you need custom vessel design, structural repair, or system upgrades, SOLAS MODU Marine’s Ship/Boat Design & Repair Division is here to help.
            </p>
            <a href="/#contact"><button className="mt-6 px-6 py-3 text-lg font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800">
              Contact Us
            </button></a>
          </div>
        </section>

      </div>
    </>
  );
};

export default ShipDesign;
