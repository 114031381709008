import React from 'react';

const Collaboration = () => {
  return (
    <>
      <div className='collab'>

        {/* Overview Section */}
        <section className="py-16 text-center px-4 md:px-8">
          <h2 className="text-4xl md:text-6xl highlight font-bold mt-12">Collaboration</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              In an industry as complex and interconnected as maritime and offshore, collaboration is key to driving innovation, improving efficiencies, and achieving shared success. At SOLAS MODU Marine Services Pvt. Ltd., we recognize the value of strong partnerships and strive to provide a platform where ideas, expertise, and resources converge.
            </p>
          </div>
        </section>

        {/* Our Collaboration Services Section */}
        <section className="py-16 px-4 md:px-8 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Our Collaboration Services</h2>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto">
            {/* Each Service */}
            {[
              { title: "Project Coordination and Support", description: "SOLAS MODU Marine provides essential coordination and logistical support to align project goals with partner capabilities. This includes stakeholder management, timeline tracking, and resource allocation to ensure seamless project execution." },
              { title: "Partner Network Development", description: "We build and strengthen a network of trusted partners across maritime and offshore industries, ensuring reliable resources for equipment, technical support, and specialized expertise." },
              { title: "Basic Consulting and Advisory Services", description: "SOLAS MODU Marine offers foundational consulting and advisory services to support project planning and strategic alignment, providing insights into industry best practices and project feasibility." },
              { title: "Resource Sharing and Equipment Access", description: "We facilitate access to essential equipment and technical expertise, helping clients meet project requirements without needing to invest in additional infrastructure." },
              { title: "Training and Knowledge Exchange", description: "Our training services strengthen team capabilities, covering operational procedures, safety standards, and industry regulations to promote effective collaboration." },
            ].map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold text-blue-700">{service.title}</h3>
                <p className="mt-4 text-gray-700">{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Building Successful Partnerships Section */}
        <section className="py-16 px-4 md:px-8 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Building Successful Partnerships with SOLAS MODU</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              Our Collaboration Division creates a supportive environment for partnerships to flourish through resource access, logistical coordination, and open communication, enabling effective teamwork and shared success.
            </p>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-16 px-4 md:px-8 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Why Choose SOLAS MODU for Collaborative Projects?</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              With a proactive approach, a growing network, and a dedication to quality, SOLAS MODU Marine’s Collaboration Division provides foundational support for successful partnerships in the maritime and offshore sectors.
            </p>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-16 px-4 md:px-8 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Contact Us</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className=" max-w-3xl mx-auto text-lg text-gray-700">
              Interested in partnering with SOLAS MODU Marine? Contact us to discuss how our Collaboration Division can support your project and help you achieve your goals.
            </p>
            <a href="/#contact"><button className="mt-6 px-6 py-3 text-lg font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800">
              Contact Us
            </button></a>
          </div>
        </section>

      </div>
    </>
  );
};

export default Collaboration;
