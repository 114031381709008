import React from 'react';

const Broking = () => {
  return (
    <>
      <div className='broking'>

        {/* Overview Section */}
        <section className="py-16 text-center">
          <h2 className="text-4xl lg:text-6xl highlight font-bold mt-12">Broking & Trading</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-4/5 lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              In the dynamic maritime and offshore industries, access to reliable, high-quality marine assets is essential. SOLAS MODU Marine Services Pvt. Ltd. offers specialized Broking & Trading services to facilitate the sale, purchase, and valuation of marine assets. We ensure smooth transactions and transparent dealings for our clients with comprehensive, industry-leading services.
            </p>
          </div>
        </section>

        {/* Our Broking & Trading Services Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl lg:text-6xl font-bold highlight">Our Broking & Trading Services</h2>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10 max-w-5xl mx-auto">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Marine Asset Brokerage</h3>
              <p className="mt-4 text-gray-700">
                Connecting buyers and sellers across global maritime markets, we facilitate transactions for vessels, rigs, and various marine assets, offering broad options tailored to client needs.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Valuation and Appraisal of Marine Assets</h3>
              <p className="mt-4 text-gray-700">
                Our expert valuation services assess asset condition, trends, and compliance to provide comprehensive, accurate appraisals for sound financial decisions.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Marine Equipment and Machinery Trading</h3>
              <p className="mt-4 text-gray-700">
                Trading a wide range of marine equipment, we source quality machinery from reliable suppliers to ensure clients’ operational success with durable, compliant equipment.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-blue-700">Consultation and Advisory Services</h3>
              <p className="mt-4 text-gray-700">
                Navigating asset trading complexities, our advisory services guide clients at each transaction stage, offering insights that align with strategic goals.
              </p>
            </div>
          </div>
        </section>

        {/* Expertise Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl lg:text-6xl font-bold highlight">Expertise in High-Value Transactions</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-4/5 lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              Understanding the financial and operational commitments involved in marine asset transactions, SOLAS MODU Marine maximizes value and minimizes risk through efficient processes tailored to each client’s needs.
            </p>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl lg:text-6xl font-bold highlight">Why Choose SOLAS MODU for Broking & Trading?</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-4/5 lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              With years of experience and a reputation for excellence, SOLAS MODU Marine’s Broking & Trading Division provides accurate valuations, reliable guidance, and seamless transaction processes for clients worldwide.
            </p>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl lg:text-6xl font-bold highlight">Contact Us</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-4/5 md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className=" max-w-3xl mx-auto text-lg text-gray-700">
              Looking to buy, sell, or value a marine asset? Contact us to discuss your requirements with our Broking & Trading division.
            </p>
            <a href="/#contact"><button className="mt-6 px-6 py-3 text-lg font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800">
              Contact Us
            </button></a>
          </div>
        </section>

      </div>
    </>
  );
};

export default Broking;
