import React, { useState } from "react";

const Careers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true); // Set loading to true

    const formData = new FormData(e.target); // Get form data

    try {
      const response = await fetch("https://formsubmit.co/b3a1238f281562c7ad9f9873edfb6ba0", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setIsModalOpen(true); // Show modal on success
        e.target.reset(); // Reset the form after submission
      } else {
        alert("There was an error submitting your application.");
      }
    } catch (error) {
      alert("There was an error: " + error.message);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className="flex items-center careers justify-center min-h-screen text-black">
      <div className="glasscontainer p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">JOIN OUR TEAM</h2>
        <form
          method="POST"
          action="https://formsubmit.co/contactsolasmodu@gmail.com" // Replace with your email
          encType="multipart/form-data"
          onSubmit={handleSubmit} // Use custom submit handler
        >
          {/* <input type="hidden" name="_next" value="http://localhost:3000/thank-you" /> */}
          <input type="hidden" name="_captcha" value="false" />

          <div className="mb-4">
            <label htmlFor="name" className="block text-black">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              required
              className="bg-white mt-1 block w-full border border-gray-300 text-black rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="bg-white mt-1 block w-full border border-gray-300 text-black rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="phone" className="block">Phone Number</label>
            <input
              type="tel"
              name="phone"
              placeholder="Your Phone Number"
              required
              className="bg-white mt-1 block w-full border border-gray-300 text-black rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="attachment" className="block">Upload Resume</label>
            <input
              type="file"
              name="attachment"
              accept=".pdf,.doc,.docx"
              required
              className="bg-white mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
            />
            <p className="mt-1 text-sm">Please ensure your resume is no larger than 5MB.</p>
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white rounded-md py-2 hover:bg-blue-600 transition duration-200 flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>

      {/* Thank You Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-80 text-center shadow-md">
            <h3 className="text-xl font-semibold mb-4">Thank You!</h3>
            <p>Your application has been submitted successfully.</p>
            <button
              onClick={() => setIsModalOpen(false)} // Close modal
              className="mt-4 bg-blue-500 text-white rounded-md py-2 px-4 hover:bg-blue-600 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Careers;
