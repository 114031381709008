// src/components/Navbar.js
import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#contact') {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#about') {
      const contactSection = document.getElementById('about');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div className="navbar bg-transparent backdrop-blur-md text-white z-50 dark:bg-transparent absolute text-xl py-3">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            className="menu menu-sm dropdown-content rounded-box z-[1] mt-3 w-64 p-2 bg-white backdrop-blur-md bg-opacity-40 shadow-lg text-lg"
          >
            <li><Link to="/" className="hover:text-white">Home</Link></li>
            <li tabIndex={0}>
              <button onClick={toggleMenu} className="hover:text-white focus:outline-none">
                Services
                <svg
                  className="fill-current ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 10l5 5 5-5H7z" />
                </svg>
              </button>
              {isOpen && (
                <ul className="absolute grid grid-cols-2 gap-4 bg-white bg-opacity-100 backdrop-blur-md p-4 text-black rounded-lg shadow-lg w-96 text-sm mt-12">
                  {/* Glass effect with two columns */}
                  <li><Link to="/Surveys" className="hover:text-white">Surveys & Certifications</Link></li>
                  <li><Link to="/ndt" className="hover:text-white">Advanced NDT Division</Link></li>
                  <li><Link to="/survival" className="hover:text-white">Survival Systems</Link></li>
                  {/* <li><Link to="/service4" className="hover:text-white">Project Management</Link></li> */}
                  <li><Link to="/shipdesign" className="hover:text-white">Ship/Boat Design & Repair Division</Link></li>
                  <li><Link to="/broking" className="hover:text-white">Broking & Trading</Link></li>
                  <li><Link to="/collaboration" className="hover:text-white">Collaboration</Link></li>
                </ul>
              )}
            </li>
            <li><Link to="/certificates" className="hover:text-white">Certifications & Licenses</Link></li>

            <li><Link to="/careers" className="hover:text-white">Careers</Link></li>
            <li><Link to="/gallery" className="hover:text-white">Gallery</Link></li>
          </ul>
        </div>
        <a href='/' className="btn btn-ghost"><img src="../images/logos/finallogo.png" className='w-28 mt-[-30px]'/></a>
        {/* <a href='/'><h1 className='ml-[-25px] text-black font-black'>SOLAS MODU</h1></a> */}
      </div>
      <div className="navbar-center hidden lg:flex bg-transparent">
        <ul className="menu menu-horizontal px-1 text-xl text-black font-semibold">
          <li><Link to="/" className="hover:text-white">Home</Link></li>
          <li><a href='./#about' className="hover:text-white">About Us</a></li>
          <li tabIndex={0} className="relative group">
            <Link to="" className="hover:text-white">
              Services
              <svg className="fill-current ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5H7z"/></svg>
            </Link>
            <ul className="absolute hidden group-hover:grid grid-cols-2 gap-4 bg-white bg-opacity-80 backdrop-blur-md p-4 text-black rounded-lg shadow-lg w-96 text-sm mt-12">
              <li><Link to="/Surveys" className="hover:text-white">Surveys & Certifications</Link></li>
              <li><Link to="/ndt" className="hover:text-white">Advanced NDT Division</Link></li>
              <li><Link to="/survival" className="hover:text-white">Survival Systems</Link></li>
              {/* <li><Link to="/s" className="hover:text-white">Project Management</Link></li> */}
              <li><Link to="/shipdesign" className="hover:text-white">Ship/Boat Design & Repair Division</Link></li>
              <li><Link to="/broking" className="hover:text-white">Broking & Trading</Link></li>
              <li><Link to="/collaboration" className="hover:text-white">Collaboration</Link></li>  

            </ul>
          </li>
          <li><Link to="/certificates" className="hover:text-white">Certifications & Licenses</Link></li>
          <li><Link to="/gallery" className="hover:text-white">Gallery</Link></li>
          <li><Link to="/careers" className="hover:text-white">Careers</Link></li>
        </ul>
      </div>
      <div className="navbar-end">
        <a href='/#contact' className="btn text-xl">Contact Us</a>
      </div>
    </div>
  );
}

export default Navbar;
