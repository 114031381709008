// src/components/OurServices.js
import React from 'react';
import Marquee from "react-fast-marquee";
const OurServices = () => {
  return (
    <>
    <section id='ourservices' className="services py-16 text-center flex flex-col items-center gap-7">
      <h1 className="text-4xl md:text-8xl text-black font-bold text-center">Our Services</h1>
      {/* <div className='flex flex-row justify-center gap-3 items-center glasscontainer'>
        <div className='flex flex-col text-2xl p-4'>
        
        </div>
        <div className='flex flex-col text-2xl p-4'>
        <h2 className="mt-4 text-black">We offer a wide range of services tailored to your needs.</h2>
        <h2 className="mt-4 text-black">We offer a wide range of services tailored to your needs.</h2>
        <h2 className="mt-4 text-black">We offer a wide range of services tailored to your needs.</h2>

        </div>

      </div> */}
      {/* <div className="flex flex-col lg:flex-row gap-5 mt-8 w-full px-32">
  <div className="card glasscontainer p-12 rounded-box grid flex-grow w-8">
        <h2 className="mt-4 text-black text-3xl">Engineering Solutions Provider to Offshore oil and Gas Industry</h2>
        <h2 className="mt-4 text-black text-3xl">🛟We offer a wide range of services tailored to your needs.</h2>
        <h2 className="mt-4 text-black text-3xl">🛟We offer a wide range of services tailored to your needs.</h2>
      </div>
  <div className="card glasscontainer rounded-box grid p-12 flex-grow w-8">
        <h2 className="mt-4 text-black text-3xl">We offer a wide range of services tailored to your needs.</h2>
        <h2 className="mt-4 text-black text-3xl">We offer a wide range of services tailored to your needs.</h2>
        <h2 className="mt-4 text-black text-3xl">We offer a wide range of services tailored to your needs.</h2>
  </div> */}
<div className='flex flex-wrap gap-8 justify-center'>
<div class="max-w-sm p-6 glasscontainer border border-gray-200 rounded-lg shadow ">
{/* <svg class="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z" clip-rule="evenodd"/>
  <path fill-rule="evenodd" d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z" clip-rule="evenodd"/>
  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/>
</svg> */}


 <h5 class="mb-2 text-2xl text-white">Surveys & Certifications</h5>
    
    <p class="mb-3 font-normal text-lg text-black">
    Class & Flag State Approved services, ISO, and Type Approvals to uphold safety and regulatory compliance.
</p>
</div>



<div class="max-w-sm p-6 glasscontainer border border-gray-200 rounded-lg shadow ">


        <h5 class="mb-2 text-2xl text-white">Advanced NDT Division</h5>
    
    <p class="mb-3 font-normal text-lg text-black">
    Precise non-destructive testing for integrity and safety assessments, essential for high-risk marine operations.</p>
</div>




<div class="max-w-sm p-6 glasscontainer border border-gray-200 rounded-lg shadow ">
{/* <svg class="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M8.597 3.2A1 1 0 0 0 7.04 4.289a3.49 3.49 0 0 1 .057 1.795 3.448 3.448 0 0 1-.84 1.575.999.999 0 0 0-.077.094c-.596.817-3.96 5.6-.941 10.762l.03.049a7.73 7.73 0 0 0 2.917 2.602 7.617 7.617 0 0 0 3.772.829 8.06 8.06 0 0 0 3.986-.975 8.185 8.185 0 0 0 3.04-2.864c1.301-2.2 1.184-4.556.588-6.441-.583-1.848-1.68-3.414-2.607-4.102a1 1 0 0 0-1.594.757c-.067 1.431-.363 2.551-.794 3.431-.222-2.407-1.127-4.196-2.224-5.524-1.147-1.39-2.564-2.3-3.323-2.788a8.487 8.487 0 0 1-.432-.287Z"/>
</svg> */}

        <h5 class="mb-2 text-2xl   text-white">Survival Systems</h5>
    
    <p class="mb-3 font-normal text-lg text-black">
     Certified service station for Life-Saving Appliances, including lifeboats, life rafts, HRU, and immersion suits, ensuring optimal readiness and reliability.</p>
</div>


<div class="max-w-sm p-6 glasscontainer border border-gray-200 rounded-lg shadow ">
{/* <svg class="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M15.03 9.684h3.965c.322 0 .64.08.925.232.286.153.532.374.717.645a2.109 2.109 0 0 1 .242 1.883l-2.36 7.201c-.288.814-.48 1.355-1.884 1.355-2.072 0-4.276-.677-6.157-1.256-.472-.145-.924-.284-1.348-.404h-.115V9.478a25.485 25.485 0 0 0 4.238-5.514 1.8 1.8 0 0 1 .901-.83 1.74 1.74 0 0 1 1.21-.048c.396.13.736.397.96.757.225.36.32.788.269 1.211l-1.562 4.63ZM4.177 10H7v8a2 2 0 1 1-4 0v-6.823C3 10.527 3.527 10 4.176 10Z" clip-rule="evenodd"/>
</svg> */}

        <h5 class="mb-2 text-2xl  text-white">Project Management & Consulting for Rigs and Ships</h5>
    
    <p class="mb-3 font-normal text-lg text-black">
    Expertise in repair, drydocking, new construction, and offshore asset management, including comprehensive contract drafting and negotiation.</p>
</div>


<div class="max-w-sm p-6 glasscontainer border border-gray-200 rounded-lg shadow ">
{/* <svg class="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M7 2a2 2 0 0 0-2 2v1a1 1 0 0 0 0 2v1a1 1 0 0 0 0 2v1a1 1 0 1 0 0 2v1a1 1 0 1 0 0 2v1a1 1 0 1 0 0 2v1a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H7Zm3 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm-1 7a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3 1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1Z" clip-rule="evenodd"/>
</svg> */}

        <h5 class="mb-2 text-2xl  text-white">Ship/Boat Design & Repair Division
</h5>
    <p class="mb-3 font-normal text-lg text-black">
    Custom design and repair solutions for a variety of marine vessels. Specializing in building durable fast craft, including yachts, ferries, patrol boats, and pilot boats.</p>
</div>
<div class="max-w-sm p-6 glasscontainer border border-gray-200 rounded-lg shadow ">
{/* <svg class="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M12.356 3.066a1 1 0 0 0-.712 0l-7 2.666A1 1 0 0 0 4 6.68a17.695 17.695 0 0 0 2.022 7.98 17.405 17.405 0 0 0 5.403 6.158 1 1 0 0 0 1.15 0 17.406 17.406 0 0 0 5.402-6.157A17.694 17.694 0 0 0 20 6.68a1 1 0 0 0-.644-.949l-7-2.666Z"/>
</svg> */}

        <h5 class="mb-2 text-2xl  text-white">Broking & Trading</h5>
    <p class="mb-3 font-normal text-lg text-black">
    Brokerage and trading of marine assets, supported by a team of maritime experts, surveyors, valuers, and loss assessors.</p>
</div>



</div>

<section id="services" className="py-16 text-center partners flex flex-col items-center gap-7">
  <h1 className="text-2xl text-black font-bold text-center">Trusted By</h1>
  <div>
    <Marquee autoFill={true}>
      <img src="./images/logos/0.png" className="h-32 mx-6" />
      <img src="./images/logos/1.png" className="h-32 mx-6" />
      <img src="./images/logos/2.png" className="h-32 mx-6" />
      <img src="./images/logos/3.png" className="h-32 mx-6" />
      <img src="./images/logos/4.png" className="h-32 mx-6" />
      <img src="./images/logos/5.png" className="h-32 mx-6" />
      <img src="./images/logos/6.png" className="h-32 mx-6" />
      <img src="./images/logos/7.png" className="h-32 mx-6" />
      <img src="./images/logos/8.jpg" className="h-32 mx-6" />
      <img src="./images/logos/9.jpeg" className="h-32 mx-6" />
      <img src="./images/logos/10.png" className="h-32 mx-6" />
      <img src="./images/logos/10_5.png" className="h-32 mx-2" />
      <img src="./images/logos/11.png" className="h-32 mx-2" />
      <img src="./images/logos/12.png" className="h-32 mx-2" />
      <img src="./images/logos/13.png" className="h-32 mx-6" />
      
    </Marquee>
  </div>
</section>



      

    </section>
    
</>
  );
};

export default OurServices;
