import React from 'react';

const SurvivalSystems = () => {
  return (
    <>
      <div className="heli">
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl highlight font-bold mt-12">Survival Systems</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-base md:text-lg max-w-3xl mx-auto text-gray-700">At SOLAS MODU Marine Services Pvt. Ltd., our Survival Systems division focuses on delivering comprehensive
              safety solutions for marine and offshore operations. Our state-of-the-art survival systems ensure that your
              personnel are equipped to respond effectively in emergency situations, safeguarding lives and assets alike.
            </p>
          </div>
        </section>

        {/* Our Services Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Our Services</h2>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10 max-w-5xl mx-auto">
            {servicesData.map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold text-blue-700">{service.title}</h3>
                <p className="mt-4 text-gray-700 text-sm md:text-base">{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Why Choose SOLAS MODU for Survival Systems?</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className="max-w-3xl mx-auto text-base md:text-lg text-gray-700">Our experienced team understands the critical importance of survival systems in maritime operations. With
              cutting-edge technology and rigorous testing protocols, we ensure that our systems meet the highest
              standards of safety and reliability. Trust us to protect your crew and assets with proven solutions.
            </p>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl md:text-6xl font-bold highlight">Contact Us</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
          <p className=" max-w-3xl mx-auto text-base md:text-lg text-gray-700">
            Ensure your safety preparedness is at the forefront. Contact us today to learn more about our Survival
            Systems services.
          </p>
          <a href="/#contact"><button className="mt-6 px-6 py-3 text-lg font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800">
              Contact Us
            </button></a>
          </div>
        </section>
      </div>
    </>
  );
};

// Sample data for services, replace with actual data if needed
const servicesData = [
  {
    title: 'Life Rafts and Lifeboats',
    description: 'Our high-quality life rafts and lifeboats are designed for maximum reliability and safety in emergencies.',
  },
  {
    title: 'Emergency Position Indicating Radio Beacons (EPIRBs)',
    description: 'EPIRBs provide critical distress signaling capabilities, ensuring that help is on the way when you need it most.',
  },
  {
    title: 'Survival Suits',
    description: 'Our survival suits offer protection against hypothermia and exposure, allowing personnel to stay safe in harsh conditions.',
  },
  {
    title: 'Personal Flotation Devices (PFDs)',
    description: 'We supply a range of PFDs to ensure that your crew is equipped with the best protection available.',
  },
  {
    title: 'Survival Kits',
    description: 'Our comprehensive survival kits contain essential items for emergency situations, enhancing crew safety.',
  },
];

export default SurvivalSystems;
