import React from 'react';

const AdvancedNDT = () => {
  return (
    <>
      <div className='nqt'>
        
        <section className="py-16 text-center">
          <h2 className="text-4xl sm:text-6xl highlight font-bold mt-12">Advanced NDT Division</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg sm:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              In the high-stakes world of marine and offshore operations, ensuring the structural integrity of your assets is paramount. SOLAS MODU Marine Services Pvt. Ltd. specializes in advanced Non-Destructive Testing (NDT) techniques, providing precise and reliable assessments that help maintain asset safety, prolong operational life, and support compliance with strict industry standards.
            </p>
          </div>
        </section>

        {/* Our Advanced NDT Services Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl sm:text-6xl font-bold highlight">Our Advanced NDT Services</h2>
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10 max-w-5xl mx-auto">
            {servicesData.map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold text-blue-700">{service.title}</h3>
                <p className="mt-4 text-gray-700"><span className="font-semibold text-gray-700">Description:</span> {service.description}</p>
                <p className="mt-4 text-gray-700"><span className="font-semibold text-gray-700">Applications:</span> {service.applications}</p>
                <p className="mt-4 text-gray-700"><span className="font-semibold text-gray-700">Benefits:</span> {service.benefits}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Specialized Applications Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl sm:text-6xl font-bold highlight">Specialized Applications in High-Risk Environments</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg sm:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              Our Advanced NDT Division is well-equipped to support industries where asset integrity is crucial. NDT solutions provided by SOLAS MODU Marine are tailored to meet the unique demands of marine and offshore environments, ensuring:
            </p>
            <ul className="list-disc list-inside text-left text-gray-700 mt-4">
              <li>Early Corrosion Detection: Through cutting-edge NDT methods, we detect corrosion at early stages, allowing for timely maintenance that prevents asset failure.</li>
              <li>Enhanced Safety & Compliance: Our services help clients comply with rigorous safety standards and prevent operational risks.</li>
              <li>Extended Asset Lifespan: Routine NDT assessments mitigate wear and tear, optimizing the lifespan of valuable assets in corrosive marine conditions.</li>
              <li>Cost-Efficient Solutions: NDT techniques like LRUT and PEC minimize the need for dismantling or insulation removal, reducing costs and downtime.</li>
            </ul>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl sm:text-6xl font-bold highlight">Why Choose SOLAS MODU Marine for Advanced NDT?</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg sm:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700">
              With a team of certified NDT specialists and years of experience in maritime safety, SOLAS MODU Marine’s Advanced NDT Division is a trusted partner for maintaining asset integrity and operational safety. Our commitment to precision, innovation, and reliability ensures you receive actionable insights and dependable results.
            </p>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-16 px-6 lg:px-20 text-center">
          <h2 className="text-4xl sm:text-6xl font-bold highlight">Contact Us</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-[75%] lg:w-[50%] mt-4 mx-auto">
            <p className="max-w-3xl mx-auto text-lg text-gray-700">
              Get in touch with our Advanced NDT Division today to schedule a consultation and learn more about how our specialized NDT solutions can support your asset integrity and compliance goals.
            </p>
            <a href="/#contact"><button className="mt-6 px-6 py-3 text-lg font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800">
              Contact Us
            </button></a>
          </div>
        </section>
      </div>
    </>
  );
};

// Sample data for services, replace with actual data if needed
const servicesData = [
  {
    title: 'Long Range Ultrasonic Testing (LRUT) / Guided Wave Ultrasonic Testing (GU)',
    description: 'LRUT, also known as Guided Wave Ultrasonic Testing, enables rapid and comprehensive screening of pipelines with diameters ranging from 2” to 72” for internal and external corrosion, erosion, and other anomalies.',
    applications: 'Ideal for long-length pipelines, especially those with areas inaccessible for traditional UT (Ultrasonic Testing). It allows cost-effective inspection without extensive manpower or access alterations.',
    benefits: 'With LRUT, one operator can cover extensive pipeline areas, identifying corrosion under insulation (CUI) and ensuring thorough, efficient coverage for pipes and tubing.',
  },
  {
    title: 'Pulsed Eddy Current Testing (PEC)',
    description: 'Pulsed Eddy Current Testing is a versatile, volumetric screening tool that measures remaining wall thickness in insulated components without needing to remove insulation or coating.',
    applications: 'Ideal for detecting corrosion and erosion in pipelines, risers, and other insulated assets.',
    benefits: 'PEC’s quick and reliable results allow for uninterrupted operations during inspections, reducing downtime.',
  },
  {
    title: 'Automated Ultrasonic Testing (AUT) / Time of Flight Diffraction (TOFD) / Phased Array',
    description: 'AUT combines TOFD and Phased Array techniques, using computerized ultrasound to assess weld integrity, corrosion, and other structural issues.',
    applications: 'Commonly used in plant piping, pipeline butt weld inspections, and storage tanks.',
    benefits: 'AUT delivers high accuracy in defect detection and dimensions, ensuring regulatory compliance and long-term asset reliability.',
  },
  {
    title: 'Short Range Ultrasonic Testing (SRUT)',
    description: 'SRUT, also known as Guided Laminar Wave Testing, uses pulsed guided laminar waves to detect near-surface defects.',
    applications: 'Particularly effective in near-surface corrosion assessments, especially where access to both sides of the structure is restricted.',
    benefits: 'This technique provides quick results, is non-invasive, and ensures minimal surface preparation.',
  },
];

export default AdvancedNDT;
