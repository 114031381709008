import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <section id='hero' className="hero h-screen text-black flex items-center justify-start px-4 md:px-16 lg:px-36">
      <div className='flex flex-col gap-4 text-center md:text-left mt-[-40px]'>
        <h1 className="text-4xl sm:text-8xl lg:text-8xl font-bold lg:w-[90%]">SOLAS MODU <br/> <h1 className='text-7xl'>MARINE SERVICES</h1><p className='text-5xl font-bold'>PRIVATE LIMITED</p></h1>
        <p className="text-base sm:text-md md:text-2xl lg:w-[55%] glasscontainerhead font-semibold">Delivering Innovative Services in the Marine Industry, Turning Offshore Challenges into Smooth Sailing</p>
        {/* <button className="btn btn-primary mt-5 w-full sm:w-44 text-2xl">Get Started</button>       */}
      </div>
    </section>
  );
};

export default HeroSection;
