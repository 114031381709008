export const images = [
  {
    src: "../gallery/1.jpg",
    original: "../gallery/1.jpg",
    width: 320,
    height: 184,
  },
  {
    src: "../gallery/2.jpg",
    original: "../gallery/2.jpg",
    width: 320,
    height: 222,
  },
  {
    src: "../gallery/3.jpg",
    original: "../gallery/3.jpg",
    width: 320,
    height: 222,
  },
  {
    src: "../gallery/4.png",
    original: "../gallery/4.png",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/5.png",
    original: "../gallery/5.png",
    width: 320,
    height: 193,
  },
  {
    src: "../gallery/6.png",
    original: "../gallery/6.png",
    width: 240,
    height: 330,
  },
  {
    src: "../gallery/7.jpg",
    original: "../gallery/7.jpg",
    width: 320,
    height: 200,
  },
  {
    src: "../gallery/8.png",
    original: "../gallery/8.png",
    width: 320,
    height: 158,
  },
  {
    src: "../gallery/9.jpg",
    original: "../gallery/9.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/10.png",
    original: "../gallery/10.png",
    width: 248,
    height: 330,
  },
  {
    src: "../gallery/11.jpg",
    original: "../gallery/11.jpg",
    width: 320,
    height: 123,
  },
  {
    src: "../gallery/12.jpg",
    original: "../gallery/12.jpg",
    width: 313,
    height: 330,
  },
  {
    src: "../gallery/13.png",
    original: "../gallery/13.png",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/14.jpg",
    original: "../gallery/14.jpg",
    width: 320,
    height: 204,
  },
  {
    src: "../gallery/27.jpg",
    original: "../gallery/27.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/16.png",
    original: "../gallery/16.png",
    width: 271,
    height: 330,
  },
  {
    src: "../gallery/17.jpg",
    original: "../gallery/17.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/18.jpg",
    original: "../gallery/18.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/19.png",
    original: "../gallery/19.png",
    width: 320,
    height: 184,
  },
  {
    src: "../gallery/20.png",
    original: "../gallery/20.png",
    width: 320,
    height: 222,
  },
  {
    src: "../gallery/21.jpg",
    original: "../gallery/21.jpg",
    width: 320,
    height: 222,
  },
  {
    src: "../gallery/22.jpg",
    original: "../gallery/22.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/23.jpg",
    original: "../gallery/23.jpg",
    width: 320,
    height: 193,
  },
  {
    src: "../gallery/24.png",
    original: "../gallery/24.png",
    width: 240,
    height: 330,
  },
  {
    src: "../gallery/25.jpg",
    original: "../gallery/25.jpg",
    width: 320,
    height: 200,
  },
  {
    src: "../gallery/26.jpg",
    original: "../gallery/26.jpg",
    width: 320,
    height: 158,
  },
  {
    src: "../gallery/image2.jpg",
    original: "../gallery/image2.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/image1.png",
    original: "../gallery/image1.png",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/15.jpg",
    original: "../gallery/15.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/28.jpeg",
    original: "../gallery/28.jpeg",
    width: 248,
    height: 330,
  },
  {
    src: "../gallery/29.jpg",
    original: "../gallery/29.jpg",
    width: 320,
    height: 123,
  },
  {
    src: "../gallery/30.jpg",
    original: "../gallery/30.jpg",
    width: 313,
    height: 330,
  },
  {
    src: "../gallery/31.jpg",
    original: "../gallery/31.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/32.jpg",
    original: "../gallery/32.jpg",
    width: 320,
    height: 204,
  },
  {
    src: "../gallery/33.jpg",
    original: "../gallery/33.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/34.jpg",
    original: "../gallery/34.jpg",
    width: 320,
    height: 223,
  },
  {
    src: "../gallery/image3.png",
    original: "../gallery/image3.png",
    width: 320,
    height: 223,
  },
];
