// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import Navbar component
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import OurServices from './components/OurServices';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Careers from './components/Careers'; 
import Surveys from './components/services/Surveys';
import AdvancedNDT from './components/services/AdvancedNDT';
import Survival from './components/services/Survival';
import Broking from './components/services/Broking';
import Collaboration from './components/services/Collaboration';
import ShipDesign from './components/services/ShipDesign';
import Certificates from './components/services/Certificates';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar /> {/* Add Navbar here */}
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <HeroSection />
                <AboutUs />
                <OurServices />
                <ContactUs />
              </>
            } 
          />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/careers" element={<Careers />} />
          {/* <Route path="/services" element={<OurServices />} /> */}
          {/* <Route path="/about" element={<AboutUs />} /> */}
          {/* Nested routes for services */}
          <Route path="/surveys" element={<Surveys />} />
          <Route path="/ndt" element={<AdvancedNDT />} />
          <Route path="/survival" element={<Survival />} />
          <Route path="/broking" element={<Broking />} />
          <Route path="/collaboration" element={<Collaboration />} />
          <Route path="/shipdesign" element={<ShipDesign />} />
          <Route path="/certificates" element={<Certificates />} />

          {/* Add more service routes as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
