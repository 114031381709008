import React, { useState } from 'react';
import { Gallery as ImageGallery } from 'react-grid-gallery'; // Renaming to avoid conflict with component name
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { images } from './images'; // Assuming images and CustomImage are defined in images.js

const Gallery = () => {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <section className="py-16 text-center bg-gray-700">
      <h2 className="text-6xl text-black font-semibold mt-12">Gallery</h2>
      {/* <p className="mt-4 text-gray-700">Check out our amazing projects!</p> */}

      {/* React Grid Gallery */}
      <div className="mt-8">
        <ImageGallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
      </div>

      {/* React Image Lightbox */}
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </section>
  );
};

export default Gallery;
