import React from 'react';

const AboutUs = () => {
  return (
    <section id='about' className="about text-center py-16">
      <div className="flex flex-col-reverse lg:flex-row gap-5 mt-8 w-full px-6 md:px-16 lg:px-32">
      <div className="flex-grow w-full lg:w-8 flex justify-center items-center">
          <h2 className='text-4xl md:text-6xl lg:text-8xl glasscontainerhead text-black font-bold'>About Us</h2>
        </div>
        <div className="card glasscontainer p-6 md:p-12 rounded-box flex-grow w-full lg:w-8">
          <h2 className="mt-4 text-black text-sm md:text-lg text-justify">
          At SOLAS MODU Marine Services Pvt. Ltd., we specialize in delivering innovative solutions across the Marine and Offshore Oil & Gas industries. We provide comprehensive Marine Services, Surveys and Certifications, Advanced NDT Division, Survival Systems, Condition Monitoring, and Turnkey Contracting. With a strong focus on safety, compliance, and efficiency, we bring over a decade of expertise in managing and navigating high-risk, complex projects globally.<br></br>
          Our team of Marine experts thrives on transforming offshore challenges into successful operations. We take pride in leading with precision and innovation, ensuring that each project meets the highest standards of safety and compliance. Whether managing rigs or ships, we turn offshore complexities into smooth sailing, all while building solutions tailored to the unique needs of our clients.<br></br>
By partnering with SOLAS MODU, you engage with a team committed to excellence, delivering seamless, results-driven solutions that ensure compliance with Class and Statutory requirements under SOLAS and MODU Codes.

          </h2>
        </div>
        
      </div>
    </section>
  );
};

export default AboutUs;
