import React from 'react';

const Surveys = () => {
  return (
    <>
      <div className='check3'>
      
        <section className="py-8 md:py-16 text-center">
          <h2 className="text-3xl md:text-5xl lg:text-6xl highlight font-bold mt-6 md:mt-12">Surveys & Certifications</h2>
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-[90%] md:w-[70%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700 text-base md:text-lg">
              At SOLAS MODU Marine Services Pvt. Ltd., our Surveys & Certifications division is dedicated to upholding the
              highest standards of safety and regulatory compliance in the Marine and Offshore industries. We understand
              that maintaining compliance with Class and Statutory requirements is crucial for both safety and operational
              efficiency. Our team of experienced Marine Surveyors and Compliance Specialists is committed to providing
              comprehensive surveys and certification services to ensure your vessels and offshore assets meet every
              industry standard.
            </p>
          </div>
        </section>

        {/* Our Services Section */}
        <section className="py-8 md:py-16 px-4 md:px-6 lg:px-20 text-center">
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold highlight">Our Services</h2>
          <div className="mt-6 md:mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 max-w-5xl mx-auto">
            <ServiceCard title="Class & Flag State Approved Services" description="Our services are aligned with international regulatory bodies, offering Class & Flag State Approvals that are essential for operational licensing and risk management." />
            <ServiceCard title="ISO & Type Approvals" description="We support businesses in achieving ISO certifications and Type Approvals, crucial for quality management, environmental responsibility, and operational safety. These certifications demonstrate your commitment to global standards, enhancing your credibility and compliance in the maritime industry." />
            <ServiceCard title="Condition Surveys" description="Our condition surveys provide in-depth assessments of the physical state of your assets, enabling you to make informed decisions for repair, maintenance, or upgrades. These surveys are fundamental in managing asset health and ensuring operational longevity." />
            <ServiceCard title="Risk Assessment" description="By performing detailed risk assessments, we identify potential hazards and provide actionable insights to mitigate risks, protect personnel, and safeguard the environment. Our team ensures compliance with both international safety standards and client-specific safety requirements." />
            <ServiceCard title="Statutory & Regulatory Inspections" description="Our inspections are meticulously conducted to meet statutory requirements, supporting the seamless operation of your vessels and offshore assets in compliance with local and international laws." />
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className="py-8 md:py-16 px-4 md:px-6 lg:px-20 text-center">
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold highlight">Why Choose SOLAS MODU?</h2>
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-[90%] md:w-[70%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700 text-base md:text-lg">
              With more than a decade of experience, our team has the expertise needed to navigate the complexities of
              maritime compliance. We collaborate with you to tailor our services to your specific needs, ensuring timely,
              accurate, and reliable surveys and certifications. By partnering with SOLAS MODU Marine, you can trust in a
              seamless certification process that keeps your operations both compliant and efficient.
            </p>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="py-8 md:py-16 px-4 md:px-6 lg:px-20 text-center">
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold highlight">Contact Us</h2>
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-[90%] md:w-[70%] lg:w-[50%] mt-4 mx-auto">
            <p className="text-gray-700 text-base md:text-lg">
              Ready to ensure your assets meet the highest standards of compliance and safety? Contact us today to learn
              more about our Surveys & Certifications services.
            </p>
            <a href="/#contact"><button className="mt-6 px-6 py-3 text-lg font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800">
              Contact Us
            </button></a>
          </div>
        </section>
      </div>
    </>
  );
};

// Component for each service card to avoid repetitive code
const ServiceCard = ({ title, description }) => (
  <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg">
    <h3 className="text-xl md:text-2xl font-semibold text-blue-700">{title}</h3>
    <p className="mt-2 md:mt-4 text-gray-700 text-base md:text-lg">{description}</p>
  </div>
);

export default Surveys;
